<template>
  <div>
    <!-- <el-card>
      <el-tag>首页</el-tag>
      <el-form ref="form" :model="seoConfig.home_page" label-width="80px">
        <el-form-item label="seo标题">
          <el-input v-model="seoConfig.home_page.title"></el-input>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="seoConfig.home_page.keyword" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="项目描述">
          <el-input v-model="seoConfig.home_page.desc" type="textarea" rows="5"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-tag>资讯栏目页</el-tag>
      <el-form ref="form" :model="seoConfig.article_page" label-width="80px">
        <el-form-item label="seo标题">
          <el-input v-model="seoConfig.article_page.title"></el-input>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="seoConfig.article_page.keyword" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="项目描述">
          <el-input v-model="seoConfig.article_page.desc" type="textarea" rows="5"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-tag>活动栏目页</el-tag>
      <el-form ref="form" :model="seoConfig.activity_page" label-width="80px">
        <el-form-item label="seo标题">
          <el-input v-model="seoConfig.activity_page.title"></el-input>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="seoConfig.activity_page.keyword" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="项目描述">
          <el-input v-model="seoConfig.activity_page.desc" type="textarea" rows="5"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-tag>快讯栏目页</el-tag>
      <el-form ref="form" :model="seoConfig.news_page" label-width="80px">
        <el-form-item label="seo标题">
          <el-input v-model="seoConfig.news_page.title"></el-input>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="seoConfig.news_page.keyword" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="项目描述">
          <el-input v-model="seoConfig.news_page.desc" type="textarea" rows="5"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-tag>报告栏目页</el-tag>
      <el-form ref="form" :model="seoConfig.report_page" label-width="80px">
        <el-form-item label="seo标题">
          <el-input v-model="seoConfig.report_page.title"></el-input>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="seoConfig.report_page.keyword" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="项目描述">
          <el-input v-model="seoConfig.report_page.desc" type="textarea" rows="5"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-tag>搜索栏目页</el-tag>
      <el-form ref="form" :model="seoConfig.search_page" label-width="80px">
        <el-form-item label="seo标题">
          <el-input v-model="seoConfig.search_page.title"></el-input>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input v-model="seoConfig.search_page.keyword" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="项目描述">
          <el-input v-model="seoConfig.search_page.desc" type="textarea" rows="5"></el-input>
        </el-form-item>
      </el-form>
    </el-card> -->
    <el-card v-for="(item, index, key) in seoConfig" :key="index">
      <el-tag>{{ arr[key] }}</el-tag>
      <el-form ref="form" :model="seoConfig[index]" label-width="80px">
        <el-form-item label="seo标题">
          <el-input v-model="seoConfig[index].title"></el-input>
        </el-form-item>
        <el-form-item label="关键字">
          <el-input
            v-model="seoConfig[index].keyword"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目描述">
          <el-input
            v-model="seoConfig[index].desc"
            type="textarea"
            rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <el-col :span="8" style="margin-top: 50px">
      <el-button type="primary" @click="subimt">{{
        btn ? '提交' : 'loading..'
      }}</el-button>
    </el-col>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btn: true,
      imageUrl: '',
      seoConfig: {
        home_page: {
          title: '',
          keyword: '',
          desc: ''
        },
        article_page: {
          title: '',
          keyword: '',
          desc: ''
        },
        activity_page: {
          title: '',
          keyword: '',
          desc: ''
        },
        news_page: {
          title: '',
          keyword: '',
          desc: ''
        },
        report_page: {
          title: '',
          keyword: '',
          desc: ''
        },
        search_page: {
          title: '',
          keyword: '',
          desc: ''
        },
        circle_page: {
          title: '',
          keyword: '',
          desc: ''
        },
        enterprise_page: {
          title: '',
          keyword: '',
          desc: ''
        },
        cooperation_page: {
          title: '',
          keyword: '',
          desc: ''
        },
        topics_page: {
          title: '',
          keyword: '',
          desc: ''
        }
      },
      arr: [
        '首页',
        '资讯栏目页',
        '活动栏目页',
        '快讯栏目页',
        '报告栏目页',
        '搜索栏目页',
        '圈子栏目页',
        '企业栏目页',
        '合作栏目页',
        '专题栏目项'
      ]
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    subimt() {
      if (this.btn) {
        console.log(this.seo)
        this.$http
          .post('/admin/SysConfig/editSeoConfig', {
            seoConfig: this.seoConfig
          })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success('操作成功')
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    getlist() {
      this.$http.get('/admin/SysConfig/getDetail').then(({ data: res }) => {
        if (res.errorCode == 200) {
          if (res.data.seo_config.search_page) {
            this.seoConfig = res.data.seo_config
          }
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-tag--light {
  margin-bottom: 50px;
  line-height: 30px;
  width: 90px;
  height: 30px;
  text-align: center;
  font-size: 14px;
}
.is-always-shadow {
  margin-top: 10px;
}
</style>
